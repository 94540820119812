import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import NotationGroup from "../../../../../components/notation-group"
import NotationSolution from "../../../../../components/notation-solution"
import PoemBildelementeNotation from "../../../../../content/kurse/hoelderlins-reisen/02-reiseerinnerung/poem-bildelemente-notation"
import FeebackHeaderProgress from "../../../../../components/feedback-header-progress"
import Score from "../../../../../components/score"

import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const { answerStatusForDict, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  const task = {
    courseId: "hoelderlins-reisen",
    chapterId: "02-reiseerinnerung",
    taskId: "bildelemente",
  }
  const comparator = ({ answer, solution, status }) => {
    if (answer?.active === true && solution === true) {
      return status.VALID_CHECKED
    }
    if (answer?.active === true && solution !== true) {
      return status.INVALID
    }
    if (solution && !answer?.active) {
      return status.MISSED
    }
    return status.VALID_UNCHECKED
  }
  const answers = answerStatusForDict({
    ...task,
    comparator,
  })
  const statistics = getStatsForTaskWithDict({
    ...task,
    comparator,
  })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/melodie-des-gedichts" />
      }
      navigation={navigation}
    >
      <Seo title="Bildelemente" />
      <Stack>
        <Heading as="h2" level={2}>
          Bildelemente
        </Heading>
        <FeebackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />

        <TaskSidebar
          main={
            <NotationGroup initialState={answers}>
              <PoemBildelementeNotation notationType={NotationSolution} />
            </NotationGroup>
          }
          sidebar={<Score statistics={statistics} />}
        ></TaskSidebar>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
